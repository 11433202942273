"use strict";
import { PortfolioLogo } from "components/AccountDrawer/MiniPortfolio/PortfolioLogo";
import styled from "lib/styled-components";
import { UniverseChainId } from "uniswap/src/types/chains";
export const MissingImageLogo = styled.div`
  --size: ${({ $size }) => $size};
  border-radius: 100px;
  color: ${({ $textColor }) => $textColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  font-size: calc(var(--size) / 3);
  font-weight: 535;
  height: ${({ $size }) => $size ?? "24px"};
  line-height: ${({ $size }) => $size ?? "24px"};
  text-align: center;
  width: ${({ $size }) => $size ?? "24px"};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LogoContainer = styled.div`
  position: relative;
  display: flex;
`;
export default function AssetLogo({
  currency,
  chainId = UniverseChainId.AbstractTestnet,
  size = 24,
  style,
  loading
}) {
  return <LogoContainer style={{ height: size, width: size, ...style }}><PortfolioLogo currencies={currency ? [currency] : []} size={size} chainId={chainId} loading={loading} /></LogoContainer>;
}
