"use strict";
import Row from "components/Row";
import PillMultiToggle from "components/Toggle/PillMultiToggle";
import { atom, useAtom } from "jotai";
import { atomWithStorage, useAtomValue, useUpdateAtom } from "jotai/utils";
import styled, { useTheme } from "lib/styled-components";
import ms from "ms";
import { useCallback, useEffect, useMemo } from "react";
import { Moon, Sun } from "react-feather";
import { ThemedText } from "theme/components/text";
import { Moon as MoonFilled } from "ui/src/components/icons/Moon";
import { Sun as SunFilled } from "ui/src/components/icons/Sun";
import { Trans, useTranslation } from "uniswap/src/i18n";
import { addMediaQueryListener, removeMediaQueryListener } from "utils/matchMedia";
const THEME_UPDATE_DELAY = ms(`0.1s`);
const DARKMODE_MEDIA_QUERY = window.matchMedia("(prefers-color-scheme: dark)");
export var ThemeMode = /* @__PURE__ */ ((ThemeMode2) => {
  ThemeMode2[ThemeMode2["LIGHT"] = 0] = "LIGHT";
  ThemeMode2[ThemeMode2["DARK"] = 1] = "DARK";
  ThemeMode2[ThemeMode2["AUTO"] = 2] = "AUTO";
  return ThemeMode2;
})(ThemeMode || {});
const OptionPill = styled.div`
  padding: 6px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CompactOptionPill = styled.div`
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
`;
const systemThemeAtom = atom(
  DARKMODE_MEDIA_QUERY.matches ? 1 /* DARK */ : 0 /* LIGHT */
);
const themeModeAtom = atomWithStorage("interface_color_theme", 2 /* AUTO */);
export function SystemThemeUpdater() {
  const setSystemTheme = useUpdateAtom(systemThemeAtom);
  const listener = useCallback(
    (event) => {
      setSystemTheme(event.matches ? 1 /* DARK */ : 0 /* LIGHT */);
    },
    [setSystemTheme]
  );
  useEffect(() => {
    addMediaQueryListener(DARKMODE_MEDIA_QUERY, listener);
    return () => removeMediaQueryListener(DARKMODE_MEDIA_QUERY, listener);
  }, [setSystemTheme, listener]);
  return null;
}
export function ThemeColorMetaUpdater() {
  const isDark = useIsDarkMode();
  useEffect(() => {
    const meta = document.querySelector("meta[name=theme-color]");
    const html = document.documentElement;
    if (!meta) {
      return;
    }
    if (isDark) {
      meta.setAttribute("content", "rgb(19, 19, 19)");
      html.classList.add("dark");
    } else {
      meta.setAttribute("content", "#fff");
      html.classList.remove("dark");
    }
  }, [isDark]);
  return null;
}
export function useIsDarkMode() {
  const mode = useAtomValue(themeModeAtom);
  const systemTheme = useAtomValue(systemThemeAtom);
  return (mode === 2 /* AUTO */ ? systemTheme : mode) === 1 /* DARK */;
}
export function useDarkModeManager() {
  const isDarkMode = useIsDarkMode();
  const setMode = useUpdateAtom(themeModeAtom);
  return useMemo(() => {
    return [isDarkMode, setMode];
  }, [isDarkMode, setMode]);
}
const ThemePillMultiToggleContainer = styled.div`
  width: fit;
`;
export function ThemeSelector({ disabled, compact = false }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [mode, setMode] = useAtom(themeModeAtom);
  const switchMode = useCallback(
    (mode2) => {
      !disabled && setTimeout(() => setMode(mode2), THEME_UPDATE_DELAY);
    },
    [disabled, setMode]
  );
  const compactOptions = [
    {
      value: 2 /* AUTO */,
      display: <CompactOptionPill data-testid="theme-auto">{t("settings.setting.appearance.option.auto")}</CompactOptionPill>
    },
    {
      value: 0 /* LIGHT */,
      display: <CompactOptionPill data-testid="theme-light"><SunFilled size="$icon.20" /></CompactOptionPill>
    },
    {
      value: 1 /* DARK */,
      display: <CompactOptionPill data-testid="theme-dark"><MoonFilled size="$icon.20" /></CompactOptionPill>
    }
  ];
  const defaultOptions = [
    {
      value: 2 /* AUTO */,
      display: <OptionPill data-testid="theme-auto">{t("settings.setting.appearance.option.auto")}</OptionPill>
    },
    {
      value: 0 /* LIGHT */,
      display: <OptionPill data-testid="theme-light"><Sun size="20" /></OptionPill>
    },
    {
      value: 1 /* DARK */,
      display: <OptionPill data-testid="theme-dark"><Moon size="20" /></OptionPill>
    }
  ];
  return <ThemePillMultiToggleContainer><PillMultiToggle
    options={compact ? compactOptions : defaultOptions}
    currentSelected={mode}
    onSelectOption={switchMode}
    activePillColor={theme.accent2}
    activeTextColor={theme.accent1}
  /></ThemePillMultiToggleContainer>;
}
export default function ThemeToggle({ disabled }) {
  return <Row align="center" justify="space-between">
    <Row width="40%"><ThemedText.SubHeaderSmall color="primary"><Trans i18nKey="themeToggle.theme" /></ThemedText.SubHeaderSmall></Row>
    <ThemeSelector disabled={disabled} />
  </Row>;
}
